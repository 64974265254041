
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageSellerPartPageSlider extends Vue {
    @Prop({ default: false }) loading!: boolean;

    @Prop({ default: false }) isEmpty!: boolean;

    @Prop({ default: false }) open!: boolean;

    @Emit("close")
    handleClose() {
        return;
    }
}
